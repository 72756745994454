import React, { useState, useEffect, useRef } from 'react'
import {
  GoogleMap,
  Marker,
  TrafficLayer,
  TransitLayer,
  StandaloneSearchBox,
} from '@react-google-maps/api'

const mapContainerStyle = {
  width: '100%',
  height: '400px',
}

const australiaBounds = {
  north: -9.0, // Northernmost point of Australia
  south: -44.0, // Southernmost point
  west: 112.0, // Westernmost point
  east: 154.0, // Easternmost point
}

const GoogleMapComponent = ({ i, items, handleAddressSelect, handleMarkerDragEnd }) => {
  const [markerPosition, setMarkerPosition] = useState({
    lat: items.lat ?? -25.2744, // Default central Australia
    lng: items.lng ?? 133.7751,
  })

  // Optional: toggles for traffic/transit (if you still want them)
  const [showTraffic, setShowTraffic] = useState(false)
  const [showTransit, setShowTransit] = useState(false)
  // const [showBicycling, setShowBicycling] = useState(false)

  // We'll keep a ref to the StandaloneSearchBox instance
  const searchBoxRef = useRef(null)

  // If 'items' changes externally, update the marker
  useEffect(() => {
    if (items.lat && items.lng) {
      setMarkerPosition({ lat: items.lat, lng: items.lng })
    }
  }, [items.lat, items.lng])

  // Called after StandaloneSearchBox is mounted
  const onSearchBoxLoad = (ref) => {
    searchBoxRef.current = ref
  }

  // Called when the user selects a place in the search box
  const onPlacesChanged = () => {
    const searchBox = searchBoxRef.current
    if (!searchBox) return

    // 'getPlaces' returns an array of places selected by user
    const places = searchBox.getPlaces()
    if (!places || places.length === 0) return

    const place = places[0]
    if (!place.geometry || !place.geometry.location) return

    // Extract the coordinates
    const newLat = place.geometry.location.lat()
    const newLng = place.geometry.location.lng()

    // Check Australia boundary
    if (
      newLat < australiaBounds.north &&
      newLat > australiaBounds.south &&
      newLng > australiaBounds.west &&
      newLng < australiaBounds.east
    ) {
      // Update marker & call your parent function
      setMarkerPosition({ lat: newLat, lng: newLng })
      // Simulate the "place" object shape you used before:
      handleAddressSelect(place, i)
    } else {
      alert('Please select an address within Australia.')
    }
  }

  // Marker drag check (same as before)
  const handleMarkerDrag = (event) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()

    if (
      newLat < australiaBounds.north &&
      newLat > australiaBounds.south &&
      newLng > australiaBounds.west &&
      newLng < australiaBounds.east
    ) {
      setMarkerPosition({ lat: newLat, lng: newLng })
      handleMarkerDragEnd(event, i)
    } else {
      alert('Markers must stay within Australia.')
      setMarkerPosition(markerPosition) // Reset to last valid position
    }
  }

  return (
    <>
      {/* If you still want traffic/transit toggles, un-comment */}
      {/* 
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ marginRight: '1rem' }}>
          <input
            type="checkbox"
            checked={showTraffic}
            onChange={(e) => setShowTraffic(e.target.checked)}
          />
          <span style={{ marginLeft: '0.3rem' }}>Show Traffic</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={showTransit}
            onChange={(e) => setShowTransit(e.target.checked)}
          />
          <span style={{ marginLeft: '0.3rem' }}>Show Transit</span>
        </label>
      </div>
      */}

      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={markerPosition}
        zoom={15}
        options={{
          mapTypeId: 'roadmap',
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: window.google.maps.ControlPosition.TOP_LEFT,
            mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid'],
          },
          zoomControl: true,
          restriction: {
            latLngBounds: australiaBounds,
            strictBounds: true,
          },
        }}
      >
        {/* This is the in-map search box */}
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
          options={{
            // Restrict search to Australia if desired
            bounds: new window.google.maps.LatLngBounds(
              { lat: -44.0, lng: 112.0 }, // Southwest
              { lat: -9.0, lng: 154.0 }, // Northeast
            ),
            strictBounds: true,
            componentRestrictions: { country: 'au' },
          }}
        >
          {/* The actual <input> element - style to overlay where you want */}
          <input
            type="text"
            placeholder="Search within Australia..."
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `350px`,
              height: `32px`,
              margin: `8px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              backgroundColor: 'white',
              position: 'absolute',
              left: '12%',
              bottom: '0%',
              marginLeft: '-120px', // Half of width to center the box
              zIndex: 10, // Make sure it's on top of the map
            }}
          />
        </StandaloneSearchBox>

        {/* Conditionally show layers if you want them */}
        {showTraffic && <TrafficLayer />}
        {showTransit && <TransitLayer />}
        {/* {showBicycling && <BicyclingLayer />} */}

        {/* Draggable Marker */}
        <Marker position={markerPosition} draggable onDragEnd={handleMarkerDrag} />
      </GoogleMap>
    </>
  )
}

export default GoogleMapComponent
