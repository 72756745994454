import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

// Create styles
const styles = StyleSheet.create({
  inline: {
    display: 'inline-block',
    fontSize: 8,
    lineHeight: 1.5,
  },
  heading: {
    fontSize: 17,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },
  bold: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },
  bold9: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },
  bold9right: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 700,
    marginLeft: 'auto',
    fontFamily: 'InterB',
  },
  bold10right: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },
  reg8: {
    color: 'blue',
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  reg9: {
    fontSize: 8,
    lineHeight: 0,
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  reg10: {
    color: 'red',
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  page: {
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 10,
  },
  bottom: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'black',
    lineHeight: 1.2,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.2,
    alignItems: 'self-start',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.2,
  },
  margin_left: {
    marginLeft: 'auto',
  },
  tables: {
    backgroundColor: '#EFEFEF',
    margin: '0px',
    border: '0.5px solid black',
  },
  td: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
  },
  th: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },
  id: {
    width: '100%',
    padding: '5px 10px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },
  layer2: {
    paddingLeft: 0,
  },
  layer3: {
    paddingLeft: 10,
  },
})

// Create Document Component
const DailyReport = (data) => {
  const dt = data?.item ?? null
  const map_dt = data?.map_data ?? null

  const formatDate = (date) => {
    if (!date) return 'No Date Provided'
    const d = new Date(date)
    if (isNaN(d)) return 'No Date Provided'
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const day = d.getDate().toString().padStart(2, '0')
    const year = d.getFullYear()
    return `${year}-${month}-${day}`
  }

  const returnDate = () => {
    const currentdate = new Date()
    return `${currentdate.getDate()}/${
      currentdate.getMonth() + 1
    }/${currentdate.getFullYear()} - ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`
  }

  // zones function builds the report structure
  const zones = (dt, map_dt) => (
    <>
      <Text style={styles.bold9right}>Date: {returnDate()}</Text>
      {Object.keys(dt).map((firstLayer, i) => (
        <View
          break={i > 0}
          key={i}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <View style={{ width: 100 }}>
            <Text style={styles.reg9}>
              ZONE:{' '}
              <Text style={styles.reg8}>
                {map_dt.dzone.find((x) => x.id === Number(firstLayer))?.name}{' '}
              </Text>
            </Text>
          </View>
          {Object.keys(dt[firstLayer]).map((secondLayer, j) => (
            <React.Fragment key={j}>
              <View
                style={{
                  width: 120,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                <Text style={styles.reg9}>
                  TYPE:{' '}
                  <Text style={styles.reg8}>
                    {map_dt.otype.find((x) => x.id === Number(secondLayer))?.name}{' '}
                  </Text>
                </Text>
              </View>
              {Object.keys(dt[firstLayer][secondLayer]).map((thirdLayer, k) => (
                <React.Fragment key={k}>
                  <View
                    style={{
                      width: 150,
                      paddingBottom: 2,
                    }}
                  >
                    <Text style={styles.reg9}>
                      MACHINE:{' '}
                      <Text style={styles.reg8}>
                        {map_dt.machine.find((x) => x.id === Number(thirdLayer))?.name}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ width: '100%' }}>
                    {dt[firstLayer][secondLayer][thirdLayer].map((dataItem, l) => (
                      <View
                        wrap={false}
                        key={l}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          borderBottom: '0.2px solid gray',
                          marginBottom: 5,
                        }}
                      >
                        {/* Column 1 */}
                        <View
                          style={{
                            width: 120,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>{formatDate(dataItem.created_at)}</Text>
                          <Text style={styles.reg9}>{dataItem.order_note}</Text>
                        </View>
                        {/* Column 2 */}
                        <View
                          style={{
                            width: 120,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>{dataItem?.customer?.account_name ?? ''}</Text>
                        </View>
                        {/* Column 3 */}
                        <View
                          style={{
                            width: 120,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>{dataItem?.customer?.phone_work ?? ''}</Text>
                        </View>
                        {/* Column 4 */}
                        <View
                          style={{
                            width: 200,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>
                            {dataItem?.order_list[0]?.order_list_products.length >= 2 && (
                              <Text>
                                MIX - {Number(dataItem.tons_ordered).toFixed(3)}
                                {'\n'}
                              </Text>
                            )}
                            {dataItem?.order_list[0]?.order_list_products.length >= 2
                              ? dataItem?.order_list[0]?.order_list_products.map((prod, i) => (
                                  <Text key={i} style={styles.reg10}>
                                    {prod.product_name ?? ''} {Number(prod.quantity).toFixed(3)}{' '}
                                    {'\n'}
                                  </Text>
                                ))
                              : dataItem?.order_list[0]?.order_list_products.map((prod, i) => (
                                  <Text key={i}>
                                    {prod.product_name ?? ''}
                                    {', '}
                                    {prod.quantity}
                                    {' x '}
                                    {prod.quantitytype?.quantity_value ?? 1}
                                  </Text>
                                ))}
                          </Text>
                        </View>
                        {/* Column 5 */}
                        <View
                          style={{
                            width: 100,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>{dataItem?.custom_spread_rate ?? ''}</Text>
                          <Text style={styles.reg9}>{dataItem?.custom_spread_rate ?? ''}</Text>
                        </View>
                        {/* Column 6 */}
                        <View
                          style={{
                            width: 100,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <Text style={styles.reg9}>
                            {dataItem.dockets_ready === 1
                              ? 'READY'
                              : dataItem.dockets_ready === 2
                              ? 'WILL ADVICE'
                              : dataItem.dockets_ready === 3
                              ? 'IN PROGRESS'
                              : ''}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </View>
      ))}
    </>
  )

  // useMemo is now always called, and if required data is missing it shows an error message.
  const zonesContent = useMemo(() => {
    if (!dt || !map_dt) {
      return (
        <View>
          <Text>Error: Data missing</Text>
        </View>
      )
    }
    return zones(dt, map_dt)
  }, [dt, map_dt])

  return (
    <Document>
      <Page size="A4" orientation="landscape" wrap={false} style={styles.page}>
        {zonesContent}
      </Page>
    </Document>
  )
}

export default React.memo(DailyReport)
