import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import axios from 'axios'
import { CFormSelect } from '@coreui/react'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'
import { GoogleMapComponent } from 'src/components'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api'

const defaultXeroCustomer = {
  customer_phone: [
    {
      phone_type: '',
      phone_number: '',
      phone_area_code: '',
      phone_country_code: '',
    },
  ],
  customer_address: [
    {
      address: '',
      street: '',
      city: '',
      state: '',
      post_code: '',
      lat: '',
      lng: '',
      del_zone: '',
      resident_name: '',
      resident_contact: '',
      resident_name_two: '',
      resident_contact_two: '',
      location_note: '',
      contacts: [],
    },
  ],
}

// Updated defaultCenter to roughly the geographical center of Australia
const defaultCenter = {
  lat: -28.0,
  lng: 133.0,
}

// Keep the map container dimensions
const mapContainerStyle = {
  width: '100%',
  height: '400px',
}

// Approximate bounding box for Australia
const australiaBounds = {
  north: -8.0, // Top
  south: -45.0, // Bottom
  west: 110.0, // Left
  east: 155.0, // Right
}

function Create(props) {
  const isUpdateMode = Boolean(props.match.params.id)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [termList, setTermList] = useState([])

  const [xeroCustomer, setXeroCustomer] = useState(defaultXeroCustomer)

  const [xeroContact, setXeroContact] = useState({
    Phones: [
      {
        PhoneType: '',
        PhoneNumber: '',
        PhoneAreaCode: '',
        PhoneCountryCode: '',
      },
    ],
  })

  const [delList, setDelList] = useState([])
  const [errorList, setError] = useState([])

  const handleAddInput = (e) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => ({
      ...prevInput,
      customer_address: [
        ...prevInput.customer_address,
        {
          address: '',
          full_address: '',
          street: '',
          city: '',
          state: '',
          post_code: '',
          lat: defaultCenter.lat,
          lng: defaultCenter.lng,
          del_zone: '',
          resident_name: '',
          resident_contact: '',
          resident_name_two: '',
          resident_contact_two: '',
          location_note: '',
          contacts: [],
        },
      ],
    }))
  }

  const handleAddressSelect = (place, index) => {
    if (!place || !place.geometry) {
      // User typed but didn't select an Autocomplete suggestion
      return
    }

    let streetNumber = ''
    let streetName = ''
    let cityName = ''
    let stateName = ''
    let postalCode = ''

    // Parse address_components from the Google response
    if (place.address_components) {
      place.address_components.forEach((component) => {
        // Street Number (e.g. '123')
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name
        }
        // Street Name (e.g. 'Main Street')
        if (component.types.includes('route')) {
          streetName = component.long_name
        }
        // City
        if (component.types.includes('locality')) {
          cityName = component.long_name
        }
        // State
        if (component.types.includes('administrative_area_level_1')) {
          stateName = component.short_name
        }
        // Post Code
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })
    }

    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    // Combine the street number & street name (if you want them in one field)
    const streetValue = `${streetNumber} ${streetName}`.trim()

    setXeroCustomer((prevInput) => {
      const updatedAddresses = [...prevInput.customer_address]

      // Update the specific address index
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        address: place.formatted_address, // entire formatted address
        street: streetValue, // the combined street field
        city: cityName,
        state: stateName,
        post_code: postalCode,
        lat,
        lng,
      }

      return { ...prevInput, customer_address: updatedAddresses }
    })
  }

  const handleMarkerDragEnd = (event, index) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        let streetNumber = ''
        let route = ''
        let cityName = ''
        let stateName = ''
        let postalCode = '' // For Post Code

        if (results[0].address_components) {
          results[0].address_components.forEach((component) => {
            // Street Number (e.g., "123")
            if (component.types.includes('street_number')) {
              streetNumber = component.long_name
            }
            // Street Name (e.g., "Main Street")
            if (component.types.includes('route')) {
              route = component.long_name
            }
            // City / Suburb
            if (component.types.includes('locality')) {
              cityName = component.long_name
            }
            // State (administrative_area_level_1, e.g. "NSW")
            if (component.types.includes('administrative_area_level_1')) {
              stateName = component.short_name
            }
            // Post Code (e.g., "2000")
            if (component.types.includes('postal_code')) {
              postalCode = component.long_name
            }
          })
        }

        // Combine street_number + route (e.g. "123 Main Street")
        const streetValue = `${streetNumber} ${route}`.trim()

        setXeroCustomer((prevInput) => {
          const updatedAddresses = [...prevInput.customer_address]
          updatedAddresses[index] = {
            ...updatedAddresses[index],
            address: results[0].formatted_address,
            street: streetValue,
            city: cityName,
            state: stateName,
            post_code: postalCode, // <-- Populate the Post Code
            lat: newLat,
            lng: newLng,
          }
          return { ...prevInput, customer_address: updatedAddresses }
        })
      }
    })
  }

  const handleAddPhoneInput = (e) => {
    e.preventDefault()
    setXeroContact((prevInput) => ({
      ...prevInput,
      Phones: [
        ...prevInput.Phones,
        {
          PhoneType: '',
          PhoneNumber: '',
          PhoneAreaCode: '',
          PhoneCountryCode: '',
        },
      ],
    }))
  }

  const handleRemoveInput = (e, i) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => {
      const list = { ...prevInput }
      list.customer_address.splice(i, 1)
      return list
    })
  }

  const handleRemoveInputPhone = (e, i) => {
    e.preventDefault()
    setXeroContact((prevInput) => {
      const updatedPhones = [...prevInput.Phones]
      updatedPhones.splice(i, 1)
      return { ...prevInput, Phones: updatedPhones }
    })
  }

  const handleAddContact = (e, i) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => {
      const updatedAddresses = [...prevInput.customer_address]
      updatedAddresses[i].contacts = updatedAddresses[i].contacts || []
      updatedAddresses[i].contacts.push({
        contact_name: '',
        contact_number: '',
      })
      return { ...prevInput, customer_address: updatedAddresses }
    })
  }

  const handleRemoveContact = (i, contactIndex, e) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => {
      const updatedAddresses = [...prevInput.customer_address]
      updatedAddresses[i].contacts.splice(contactIndex, 1)
      return { ...prevInput, customer_address: updatedAddresses }
    })
  }

  const handleContactChange = (e, addressIndex, contactIndex) => {
    const { name, value } = e.target
    setXeroCustomer((prevInput) => {
      const updatedAddresses = [...prevInput.customer_address]
      updatedAddresses[addressIndex].contacts[contactIndex][name] = value
      return { ...prevInput, customer_address: updatedAddresses }
    })
  }

  /* Handle Address Form Inputs */
  const handleChange = (e, index) => {
    e.persist()
    setXeroCustomer((prevInput) => {
      const updatedAddresses = [...prevInput.customer_address]
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        [e.target.name]: e.target.value,
      }
      return { ...prevInput, customer_address: updatedAddresses }
    })
  }

  const updateState = (setStateFunc, prevState, propertyName, value) => {
    setStateFunc({
      ...prevState,
      [propertyName]: value,
    })
  }

  const inputNameToStatePropertyMapping = {
    account_name: 'Name',
    account_contact_name: 'FirstName',
    code: 'CompanyNumber',
    terms: 'terms',
    email: 'EmailAddress',
  }

  const phoneInputNameMapping = {
    phone_type: 'PhoneType',
    phone_number: 'PhoneNumber',
  }

  const handleChangePhone = (e, index) => {
    e.persist()
    const statePropertyName = phoneInputNameMapping[e.target.name]
    if (!statePropertyName) {
      console.warn(`No mapping found for input name: ${e.target.name}`)
      return
    }

    setXeroContact((prevInput) => {
      const updatedPhones = [...prevInput.Phones]
      const updatedPhone = { ...updatedPhones[index] }
      updatedPhone[statePropertyName] = e.target.value
      updatedPhones[index] = updatedPhone
      return { ...prevInput, Phones: updatedPhones }
    })
  }

  const handleInput = (e) => {
    e.persist()
    const statePropertyName = inputNameToStatePropertyMapping[e.target.name]
    if (!statePropertyName) {
      console.warn(`No mapping found for input name: ${e.target.name}`)
      return
    }
    updateState(setXeroContact, xeroContact, statePropertyName, e.target.value)
    updateState(setXeroCustomer, xeroCustomer, statePropertyName, e.target.value)
  }

  /* Build arrays for Xero usage (optional / per your code) */
  const Addresses = []
  const Phones = []

  xeroContact.Phones.forEach((phone) => {
    Phones.push({
      phone_type: phone.PhoneType,
      phone_number: phone.PhoneNumber,
      phone_area_code: phone.PhoneAreaCode,
      phone_country_code: phone.PhoneAreaCode,
    })
  })

  xeroCustomer?.customer_address.forEach((address) => {
    Addresses.push({
      AddressType: 'POBOX',
      AddressLine1: address?.address,
      City: address?.del_zone,
      PostalCode: 'true',
      AttentionTo: address?.resident_contact,
    })
  })

  const xeroData = {
    Contacts: [
      {
        Name: xeroCustomer?.Name,
        FirstName: xeroCustomer?.FirstName,
        LastName: '',
        CompanyNumber: xeroCustomer?.CompanyNumber,
        EmailAddress: xeroCustomer?.EmailAddress,
        Addresses,
        Phones: xeroContact?.Phones,
      },
    ],
  }

  const xeroIfNotExistingContact = {
    Contacts: [
      {
        Name: xeroCustomer?.Name,
        FirstName: xeroCustomer?.FirstName,
        LastName: '',
        CompanyNumber: xeroCustomer?.CompanyNumber,
        EmailAddress: xeroCustomer?.EmailAddress,
        Addresses,
        Phones: xeroContact?.Phones,
      },
    ],
  }

  const xeroIfNotExistingCustomer = {
    contact_id: props.match.params.id,
    account_name: xeroContact?.Name,
    account_contact_name: xeroCustomer?.FirstName,
    code: xeroCustomer?.CompanyNumber,
    terms: xeroCustomer?.terms,
    customer_address: xeroCustomer?.customer_address,
    customer_phone: Phones,
    email: xeroCustomer?.EmailAddress,
    terms: xeroCustomer?.terms,
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isUpdateMode) {
          const customer_id = props.match.params.id
          const customerRes = await axios.get(`api/edit_customers/${customer_id}`)
          if (customerRes.data.status === 200) {
            let customerData = customerRes.data.customer

            // Convert lat/lng from string to number
            customerData.customer_address = customerData.customer_address.map((addr) => {
              return {
                ...addr,
                lat: addr.lat ? parseFloat(addr.lat) : defaultCenter.lat,
                lng: addr.lng ? parseFloat(addr.lng) : defaultCenter.lng,
                // Optionally: If full_address is empty, copy `addr.address`
                address: addr.address || addr.address || '',
              }
            })

            setXeroCustomer(customerData)
            setXeroContact(customerRes.data.xero || {})
            console.log('Parsed lat & lng', customerData)
          } else {
            swal('Error', customerRes.data.message, 'error')
            history.push('/customers')
            return
          }
        }
        // If not isUpdateMode, do whatever else you do...
        const termsRes = await axios.get('/api/customer_terms')
        if (termsRes.data.status === 200) {
          setTermList(termsRes.data.customer_terms)
        }

        const deliveryRes = await axios.get('/api/delivery_zones')
        if (deliveryRes.data.status === 200) {
          setDelList(deliveryRes.data.delivery_zones)
        }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }
    fetchData()
  }, [isUpdateMode, history, props.match.params.id])

  const submitForm = async (e) => {
    e.preventDefault()

    console.log(xeroCustomer)
    try {
      let response = {}
      if (isUpdateMode) {
        const customer_id = props.match.params.id
        const id = xeroCustomer.id
        if (id) {
          response = await axios.put(`/api/update_customers/${customer_id}`, xeroCustomer)
        } else {
          response = await axios.post('/api/create_customer', xeroIfNotExistingCustomer)
        }
      } else {
        // Creating a new customer
        response = await axios.post('/api/create_customer', xeroIfNotExistingCustomer)
        response = await axios.post('/api/create_xero_contact', xeroIfNotExistingContact)
      }

      if (response.data.status === 200) {
        swal('Success', response.data.message, 'success')
        history.push('/customers')
        setError([])
      } else if (response.data.status === 422) {
        setError(response.data.errors)
      } else if (response.data.status === 404) {
        swal('Error', response.data.message, 'error')
        history.push('/customers')
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyDejtAvi04dswla46OCajg6epNjEcZk5b8" libraries={['places']}>
      <div className="content-wrapper">
        {/* Breadcrumb navigation */}
        <nav aria-label="breadcrumb p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/customers">Customers</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page"></li>
          </ol>
        </nav>

        {/* Main title */}
        <h3 className="text-dark">{isUpdateMode ? 'Xero Contact Details' : 'Add New Customer'}</h3>

        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Xero Contact</h5>
            </div>
            <div className="card-body">
              <form onSubmit={submitForm} id="product_form">
                <div className="card-body">
                  <h4 className="mb-4">Xero Contact Details</h4>
                  <div className="row">
                    {/* Customer Account Name */}
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="pb-2">
                          Customer Account Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_name"
                          onChange={handleInput}
                          value={xeroContact.Name || ''}
                          placeholder="Account Name"
                          disabled
                        />
                        <span className="text-danger">{errorList.account_name}</span>
                      </div>
                    </div>

                    {/* Account Contact Name */}
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="pb-2">
                          Account Contact Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_contact_name"
                          onChange={handleInput}
                          value={xeroContact.FirstName || ''}
                          placeholder="Account Contact Name"
                          disabled
                        />
                        <span className="text-danger">{errorList.account_contact_name}</span>
                      </div>
                    </div>

                    {/* Customer Code */}
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="pb-2">
                          Customer Code <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="code"
                          onChange={handleInput}
                          value={xeroContact.AccountNumber || ''}
                          placeholder="Customer Code"
                          disabled
                        />
                        <span className="text-danger">{errorList.AccountNumber}</span>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="pb-2">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          onChange={handleInput}
                          value={xeroContact.EmailAddress || ''}
                          placeholder="Email"
                          disabled
                        />
                        <span className="text-danger">{errorList.email}</span>
                      </div>
                    </div>
                  </div>

                  {xeroContact.Phones?.map((phone, i) => (
                    <div className="row" key={i}>
                      {/* Phone Type */}
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Phone Type </label>
                          <CFormSelect
                            aria-label="Default select example"
                            name="phone_type"
                            onChange={(e) => handleChangePhone(e, i)}
                            value={phone.PhoneType}
                            disabled
                          >
                            <option value="DEFAULT">Default</option>
                            <option value="FAX">FAX</option>
                            <option value="MOBILE">MOBILE</option>
                            <option value="DDI">DDI</option>
                          </CFormSelect>

                          <span className="text-danger">
                            {errorList[`customer_phone.${i}.phone_type`]}
                          </span>
                        </div>
                      </div>

                      {/* Phone Number */}
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Phone Number</label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone_number"
                            onChange={(e) => handleChangePhone(e, i)}
                            value={phone.PhoneNumber}
                            placeholder="Number"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList[`customer_phone.${i}.phone_number`]}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  <hr />
                  <h4 className="mb-4">Xero Contact Addresses</h4>
                  {xeroContact?.Addresses?.map((items, i) => (
                    <div key={i}>
                      <div className="row">
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">Address Line 1</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.AddressLine1 || 'N/A'}
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">Address Type</label>
                          <input
                            className="form-control"
                            type="text"
                            value={items.AddressType || 'N/A'}
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">Attention To</label>
                          <input
                            className="form-control"
                            type="text"
                            value={items.AttentionTo || 'N/A'}
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">City</label>
                          <input
                            className="form-control"
                            type="text"
                            value={items.City || 'N/A'}
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">Country</label>
                          <input
                            className="form-control"
                            type="text"
                            value={items.Country || 'N/A'}
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="pb-2">Region</label>
                          <input
                            className="form-control"
                            type="text"
                            value={items.Region || 'N/A'}
                            disabled
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}

                  <h4 className="d-flex align-items-center">
                    Customer Addresses{' '}
                    {xeroCustomer.customer_address && (
                      <div className="col-lg-2 mb-2 pt-2 ms-3">
                        <button className="btn btn-success" onClick={handleAddInput}>
                          Add Address
                        </button>
                      </div>
                    )}
                  </h4>

                  {/* Terms */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Terms <span className="text-danger">*</span>
                      </label>
                      <CFormSelect
                        aria-label="Default select example"
                        name="terms"
                        onChange={handleInput}
                        value={xeroCustomer?.terms}
                      >
                        <option value={0}>-- select --</option>
                        {termList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </CFormSelect>
                      <span className="text-danger">{errorList.terms}</span>
                    </div>
                  </div>

                  {xeroCustomer?.customer_address.map((items, i) => (
                    <div key={i}>
                      <div className="row mt-3">
                        {/* Full Address (Autocomplete + Map) */}
                        <div className="col-lg-12 mb-3">
                          <label className="pb-2">Full Address</label>

                          <GoogleMapComponent
                            i={i}
                            items={items}
                            handleAddressSelect={handleAddressSelect}
                            handleMarkerDragEnd={handleMarkerDragEnd}
                          />

                          <span className="text-danger">
                            {errorList[`customer_address.${i}.address`]}
                          </span>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-lg-4 mb-3">
                          <div className="form-group">
                            <label className="pb-2">
                              Delivery Address <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              onChange={(e) => handleChange(e, i)}
                              value={items.address}
                              placeholder="Delivery Address"
                              disabled
                            />
                            <span className="text-danger">
                              {errorList[`customer_address.${i}.address`]}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="pb-2">Street</label>
                          <input
                            className="form-control"
                            type="text"
                            name="street"
                            onChange={(e) => handleChange(e, i)} // your usual onChange
                            value={items.street} // now updated from handleAddressSelect
                            placeholder="Street"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList[`customer_address.${i}.street`]}
                          </span>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="pb-2">City / Suburb</label>
                          <input
                            className="form-control"
                            type="text"
                            name="city"
                            onChange={(e) => handleChange(e, i)} // your existing change handler
                            value={items.city} // now updated by handleAddressSelect
                            placeholder="City / Suburb"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList[`customer_address.${i}.city`]}
                          </span>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="pb-2">State</label>
                          <input
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) => handleChange(e, i)}
                            value={items.state}
                            placeholder="State"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList[`customer_address.${i}.state`]}
                          </span>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="pb-2">Post Code</label>
                          <input
                            className="form-control"
                            type="text"
                            name="post_code"
                            onChange={(e) => handleChange(e, i)}
                            value={items.post_code}
                            placeholder="Post Code"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList[`customer_address.${i}.post_code`]}
                          </span>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="pb-2">
                            Delivery Zone <span className="text-danger">*</span>
                          </label>
                          <CFormSelect
                            aria-label="Default select example"
                            name="del_zone"
                            onChange={(e) => handleChange(e, i)}
                            value={items.del_zone}
                          >
                            <option value={0}>-- select --</option>
                            {delList.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </CFormSelect>
                          <span className="text-danger">
                            {errorList[`customer_address.${i}.del_zone`]}
                          </span>
                        </div>

                        <hr />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Address Note</label>
                          <input
                            className="form-control"
                            type="text"
                            name="location_note"
                            onChange={(e) => handleChange(e, i)}
                            value={items.location_note}
                            placeholder="Address Note"
                          />
                          <span className="text-danger">
                            {errorList[`address_list.${i}.location_note`]}
                          </span>
                        </div>
                      </div>

                      <h4 className="d-flex align-items-center">
                        Contacts
                        <div className="ms-3">
                          <button
                            className="btn btn-success"
                            onClick={(e) => handleAddContact(e, i)}
                          >
                            Add Contact
                          </button>
                        </div>
                      </h4>

                      {xeroCustomer.customer_address[i].contacts?.map((contact, j) => (
                        <div className="row mt-2" key={j}>
                          <div className="col-lg-4 mb-3">
                            <label className="pb-2">Contact Person</label>
                            <input
                              className="form-control"
                              type="text"
                              name="contact_name"
                              value={contact.contact_name}
                              onChange={(e) => handleContactChange(e, i, j)}
                              placeholder="Contact Person"
                            />
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="pb-2">Contact Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="contact_number"
                              value={contact.contact_number}
                              onChange={(e) => handleContactChange(e, i, j)}
                              placeholder="Contact Number"
                            />
                          </div>
                          {xeroCustomer.customer_address[i].contacts?.length > 1 && (
                            <div className="col-lg-2 mt-4 pt-1">
                              <button
                                className="btn btn-danger"
                                onClick={(e) => handleRemoveContact(i, j, e)}
                              >
                                Remove Contact
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      {xeroCustomer.customer_address.length !== 1 && (
                        <div className="col-lg-2 pt-2">
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handleRemoveInput(e, i)}
                          >
                            Remove Address
                          </button>
                        </div>
                      )}
                      <hr />
                    </div>
                  ))}
                </div>
                <div className="mx-3">
                  <button type="submit" className="btn btn-primary">
                    {isUpdateMode ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LoadScript>
  )
}

export default Create
