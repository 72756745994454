import React, { useState, useEffect } from 'react'
import Preloader from 'src/views/preloader/Preloader'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { PDFGenerator } from 'src/components'

const DailyReports = () => {
  const [loading, setLoading] = useState(true)
  const [reportData, setReportData] = useState([])
  const [xeroContact, setXeroContact] = useState([])
  const [map_data, setMapData] = useState({})
  const history = useHistory()

  useEffect(() => {
    axios.get(`/api/show_order`).then((res) => {
      if (res.data.status === 200) {
        setMapData({
          dzone: res.data.dzone,
          otype: res.data.otype,
          machine: res.data.machine,
        })

        console.log(res.data.customerXero.body.Contacts)
        setReportData(res.data.today)
        setXeroContact(res.data.customerXero.body.Contacts)
      } else if (res.data.status === 500) {
        Swal.fire({
          icon: 'warning',
          title: 'warning',
          text: res.data.message,
        })
        history.push('/')
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Preloader />
  }

  return (
    <>
      <h1 className="mb-4">Daily Report</h1>
      {reportData.length !== 0 && map_data ? (
        <PDFGenerator dt={reportData} map_dt={map_data} xero_contact={xeroContact} />
      ) : (
        <p>No data found</p>
      )}
    </>
  )
}

export default DailyReports
