import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import Preloader from 'src/views/preloader/Preloader'
import format from 'date-fns/format'
import swal from 'sweetalert2'
import axios from 'axios'
import $ from 'jquery'

const Xero = () => {
  const [loading, setLoading] = useState(true)
  const [invoiceXero, setInvoiceXero] = useState([])
  const [productsXero, setProductsXero] = useState([])
  const [approvedInvoice, setApprovedInvoice] = useState([])
  const [skuLocation, setSkuLocation] = useState([])
  const [isSending, setIsSending] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await axios.get(`/api/xero_index`)
        if (res.data.status === 200) {
          setProductsXero(res.data.xeroItems.body.Items)
          setInvoiceXero(res.data.orders)
          setSkuLocation(res.data.sku_location)
        } else if (res.data.status === 500) {
          showAlert('warning', 'Warning', res.data.message)
          history.push('/')
        }
      } catch (error) {
        showAlert('error', 'Error', 'Failed to fetch data.')
      } finally {
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      }
    }

    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(fetchOrders)
    } else {
      setTimeout(fetchOrders, 1)
    }
  }, [history])

  const showAlert = (icon, title, text) => {
    swal.fire({ icon, title, text })
  }

  const prepareXeroData = (valueXeroState) => {
    const nonFreProducts = valueXeroState.order_list
      .flatMap((order) => order.order_list_products)
      .filter((product) => product.product_name !== 'FRE')
    const freProducts = valueXeroState.order_list
      .flatMap((order) => order.order_list_products)
      .filter((product) => product.product_name === 'FRE')
    const DocketLineItems = valueXeroState.order_list?.flatMap((products) => {
      return products.docket_numbers.flatMap((docket) => {
        let totalProportionatePrice = 0
        nonFreProducts.forEach((product) => {
          const mixPercentage = parseFloat(product.mix_percentage).toFixed(3)
          const unitPrice = parseFloat(product.unit_price).toFixed(3)
          const proportionatePrice = (mixPercentage / 100) * unitPrice
          totalProportionatePrice += proportionatePrice
        })
        const machine_xero = valueXeroState?.machine?.machine_code
        const sku_xero = valueXeroState?.skulocation?.xero_code
        const skuMix = machine_xero + 'MIX.' + sku_xero
        return (
          products.order_list_products.slice(0, 1).map((product) => {
            const xeroItem = productsXero.find((xItem) => xItem.Code === skuMix)
            return {
              ItemCode: skuMix,
              Description: xeroItem
                ? xeroItem.Description + ' - Docket Number ' + docket.docket_number
                : 'N/A',
              Quantity: docket.weight === 'N/A' || docket.weight === null ? 0 : docket.weight,
              UnitAmount: parseFloat(totalProportionatePrice).toFixed(2),
              TaxType: 'OUTPUT',
              AccountCode: xeroItem ? xeroItem.SalesDetails.AccountCode : '',
              Tracking: [
                {
                  Name: 'Location',
                  Option: docket.skulocation?.name,
                },
                {
                  Name: 'Division',
                  Option: ['BULK SELF PICKUP', 'BULK DUMPED', 'BULK AUGERED'].includes(
                    valueXeroState.ordertype.name,
                  )
                    ? 'Mining'
                    : valueXeroState.ordertype.name.trim() === 'SPREAD'
                    ? 'Spreading'
                    : 'Undefined',
                },
              ],
            }
          }) || []
        )
      })
    })
    const LineItems = valueXeroState.order_list.flatMap((products) => {
      return products.docket_numbers.flatMap((docket) => {
        let totalProportionatePrice = 0
        products.order_list_products
          .filter((product) => product.product_name !== 'FRE')
          .forEach((product) => {
            const mixPercentage = parseFloat(product.mix_percentage).toFixed(3)
            const unitPrice = parseFloat(product.unit_price).toFixed(3)
            const proportionatePrice = (mixPercentage / 100) * unitPrice
            totalProportionatePrice += proportionatePrice
          })
        const machine_xero = valueXeroState?.machine?.machine_code
        const sku_xero = valueXeroState?.skulocation?.xero_code
        const skuMix = machine_xero + 'MIX.' + sku_xero
        return (
          products.order_list_products
            .filter((product) => product.product_name !== 'FRE')
            .slice(0, 1)
            .map((product) => {
              const xeroItem = productsXero.find((xItem) => xItem.Code == product.xero_code)
              return {
                ItemCode: product.xero_code,
                Description: xeroItem
                  ? xeroItem.Description + ' - Docket Number ' + docket.docket_number
                  : 'N/A',
                Quantity: docket.weight === 'N/A' || docket.weight === null ? 0 : docket.weight,
                UnitAmount: parseFloat(totalProportionatePrice).toFixed(2),
                TaxType: 'OUTPUT',
                AccountCode: xeroItem ? xeroItem.SalesDetails.AccountCode : '',
                Tracking: [
                  {
                    Name: 'Location',
                    Option: docket.skulocation?.name,
                  },
                  {
                    Name: 'Division',
                    Option: ['BULK SELF PICKUP', 'BULK DUMPED', 'BULK AUGERED'].includes(
                      valueXeroState.ordertype.name,
                    )
                      ? 'Mining'
                      : valueXeroState.ordertype.name.trim() === 'SPREAD'
                      ? 'Spreading'
                      : 'Undefined',
                  },
                ],
              }
            }) || []
        )
      })
    })
    const FRELineItems =
      freProducts.length > 0
        ? [
            {
              ItemCode: 'FRE',
              Description: 'FREIGHT',
              Quantity: freProducts[0].quantity,
              UnitAmount: freProducts[0].unit_price,
              TaxType: 'OUTPUT',
              AccountCode: 'FRE',
              Tracking: [
                {
                  Name: 'Location',
                  Option: valueXeroState.skulocation?.name || 'Unknown Location',
                },
                {
                  Name: 'Division',
                  Option: 'Special FRE Division',
                },
              ],
            },
          ]
        : []
    const OtherLineItems =
      valueXeroState.line_items?.flatMap((lineItem) => {
        const location = skuLocation.find((loc) => loc.id == lineItem?.sku_location)
        return {
          ItemCode: lineItem.xero_code,
          Description: lineItem.description,
          Quantity:
            lineItem.quantity === 'N/A' || lineItem.quantity === null ? 0 : lineItem.quantity,
          UnitAmount: lineItem.amount === 'N/A' || lineItem.amount === null ? 0.0 : lineItem.amount,
          TaxType: 'OUTPUT',
          AccountCode: '',
          Tracking: [
            {
              Name: 'Location',
              Option: location ? location.name : 'Unknown Location',
            },
            {
              Name: 'Division',
              Option: lineItem.division
                ? parseInt(lineItem.division) === 1
                  ? 'Spreading'
                  : parseInt(lineItem.division) === 2
                  ? 'Transport'
                  : parseInt(lineItem.division) === 3
                  ? 'Mining'
                  : 'Unknown Division'
                : 'Unknown Division',
            },
          ],
        }
      }) || []
    const OtherLineItemsWithOrderNote = [
      ...OtherLineItems,
      ...(valueXeroState.order_note
        ? [
            {
              ItemCode: '',
              Description: valueXeroState.order_note,
              Quantity: '',
              UnitAmount: '',
              AccountCode: '',
            },
          ]
        : []),
    ]
    const docketNumbers = valueXeroState.order_list
      .map((order) => {
        return order.docket_numbers.map((docket) => docket.docket_number).join(', ')
      })
      .join(', ')
    const DocketNumbers = [
      {
        ItemCode: '',
        Description: docketNumbers,
        Quantity: '',
        UnitAmount: '',
        AccountCode: '',
      },
    ]
    let CombinedLineItems = []
    valueXeroState.order_list.map((product_docket) => {
      const filteredProducts = product_docket.order_list_products.filter(
        (product) => product.product_name !== 'FRE',
      )
      if (filteredProducts.length > 1) {
        CombinedLineItems = [...DocketLineItems, ...FRELineItems, ...OtherLineItemsWithOrderNote]
      } else {
        CombinedLineItems = [...LineItems, ...FRELineItems, ...OtherLineItemsWithOrderNote]
      }
    })
    const Attachments = valueXeroState.order_list?.flatMap(
      (images) =>
        images.docket_numbers?.map((image) => ({
          FileName: image.imageName,
          Url: image.imageURL,
          MimeType: 'image/png',
          ContentLength: '10294',
        })) || [],
    )
    const dateString =
      valueXeroState.dockets_complete_time === null
        ? valueXeroState.updated_at
        : valueXeroState.dockets_complete_time
    const customerTerms = valueXeroState?.customer?.customer_terms?.name || ''
    const year = parseInt(dateString.split('-')[0], 10)
    const month = parseInt(dateString.split('-')[1], 10) - 1
    function getLastDayOfMonth(year, month) {
      const lastDay = new Date(year, month + 1, 0)
      return lastDay
    }
    const lastDayOfMonth = getLastDayOfMonth(year, month)
    let dueDate = new Date(lastDayOfMonth)
    if (customerTerms === 'NET30') {
      dueDate.setDate(lastDayOfMonth.getDate() + 30)
    } else if (customerTerms === 'NET14') {
      dueDate.setDate(lastDayOfMonth.getDate() + 14)
    } else if (customerTerms === 'NET7') {
      dueDate.setDate(lastDayOfMonth.getDate() + 7)
    } else {
      dueDate = 'Invalid Date'
    }
    function formatDateToYearMonthDay(date) {
      if (date === 'Invalid Date') {
        return 'Invalid Date'
      }
      return (
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      )
    }
    const formattedDueDate = isNaN(Date.parse(dateString))
      ? 'Invalid Date'
      : formatDateToYearMonthDay(dueDate)
    const baseData = {
      Reference: valueXeroState.customer_reference,
      Type: 'ACCREC',
      Contact: {
        ContactID: valueXeroState.customer.contact_id,
        Name: valueXeroState.customer.account_name,
      },
      DateString:
        formattedDueDate === 'Invalid Date'
          ? valueXeroState.updated_at
          : valueXeroState.dockets_complete_time,
      DueDateString:
        formattedDueDate === 'Invalid Date' ? valueXeroState.updated_at : formattedDueDate,
      LineItems: CombinedLineItems,
      Attachments: Attachments || '',
    }
    return valueXeroState.invoice_id
      ? { ...baseData, InvoiceID: valueXeroState.invoice_id, OrderID: valueXeroState.id }
      : { ...baseData, OrderID: valueXeroState.id }
  }

  const sendToXero = async (id) => {
    setIsSending(id)
    const valueXeroState = invoiceXero.find((x) => x.id === Number(id))
    const xeroData = prepareXeroData(valueXeroState)
    try {
      const endpoint = valueXeroState.invoice_id
        ? `/api/update_xero_invoice`
        : `/api/create_xero_invoice`
      const res = await axios.post(endpoint, xeroData)
      if (res.data.status === 200) {
        const res2 = await axios.get(`/api/xero_index`)
        if (res2.data.status === 200) {
          setInvoiceXero(res2.data.orders)
        }
        showAlert('success', 'Success', 'Xero Invoice added successfully')
        history.push('/xero')
      }
    } catch (error) {
      let errorMessage = 'Something went wrong.'
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage
      }
      if (errorMessage.includes('Item code')) {
        showAlert('error', 'Error', 'Item Code is not Valid')
      } else if (
        error.response &&
        error.response.status === 500 &&
        (!xeroData.Attachments || xeroData.Attachments.length === 0)
      ) {
        const res2 = await axios.get(`/api/xero_index`)
        if (res2.data.status === 200) {
          setInvoiceXero(res2.data.orders)
        }
        showAlert('success', 'Success', 'Xero Invoice added successfully')
        history.push('/xero')
      } else {
        showAlert('error', 'Error', 'Something went wrong.')
      }
    }
    setIsSending(false)
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <>
      <div className="content-wrapper">
        <nav aria-label="breadcrumb p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Orders
            </li>
          </ol>
        </nav>
        <h3 className="text-dark">Xero</h3>
        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Xero Order</h5>
            </div>
            <div className="card-body">
              <table id="dataTable" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="small">#</th>
                    <th className="small">Invoice Number</th>
                    <th className="small">Code</th>
                    <th className="small">Customer Reference</th>
                    <th className="small">Address Contact</th>
                    <th className="small">Product(s)</th>
                    <th className="small">Delivery Location</th>
                    <th className="small">Last Modified</th>
                    <th className="small">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceXero.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <p className="small">#{item.id}</p>
                      </td>
                      <td>
                        <p className="small">{item.invoice_number}</p>
                      </td>
                      <td>
                        <p className="small">{item.customer.code}</p>
                      </td>
                      <td>
                        <p className="small">
                          {item.customer_reference === null ? 'N/A' : item.customer_reference}
                        </p>
                      </td>
                      <td>
                        <p className="small">
                          <span className="fw-bold">{item.customer.account_name}</span>
                          <br />
                          <span className="fw-bold">H:</span>
                          {item.customer.phone_home}
                          <br />
                          <span className="fw-bold">W:</span>
                          {item.customer.phone_work}
                          <br />
                          <span className="fw-bold">M:</span>
                          {item.customer.phone_mobile}
                        </p>
                      </td>
                      <td>
                        {item.order_list?.map((product_code, i) => (
                          <div key={i}>
                            {product_code.order_list_products?.map((code, ind) => (
                              <span className="badge bg-dark mx-1" key={ind}>
                                {code.product_name}
                              </span>
                            ))}
                          </div>
                        ))}
                      </td>
                      <td>
                        <p className="small">{item.orderdeliveryzone.name}</p>
                      </td>
                      <td>{format(new Date(item.updated_at), 'dd/MM/yyyy | HH:mm:ss')}</td>
                      <td>
                        {approvedInvoice.some(
                          (approved) => approved.InvoiceNumber === item.invoice_number,
                        ) ? (
                          <button
                            type="button"
                            className="btn btn-block btn-outline-success btn-sm"
                          >
                            Invoice Approved
                          </button>
                        ) : item.is_xero === null ? (
                          <span>Its Null</span>
                        ) : item.is_xero === 0 ? (
                          <button type="button" className="btn btn-block btn-outline-info btn-sm">
                            Xero Updated
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-block btn-outline-success btn-sm"
                            onClick={() => sendToXero(item.id)}
                            disabled={isSending !== false}
                          >
                            {isSending === item.id ? 'Sending' : 'Send to Xero'}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Xero
