export const Agents = `/api/agents`
export const Line_Items = `/api/line_items`
export const Crops = `/api/crops`
export const CustomerTerms = `api/customer_terms`
export const Delivery_Zones = `api/delivery_zones`
export const Machines = `api/machines`
export const OrderTypes = `api/order_types`
export const Operators = `api/operators`
export const Products = `api/products`
export const ProductMix = `api/product_mix`
export const Quantity = `api/quantity`
export const SpreadChargeRate = `api/spread_charge_rate`
export const SkuLocation = `api/sku_location`
export const SubOrderType = `api/sub_order_types`
export const Orders = `api/orders`
export const Customers = `/api/customers`
export const Xero = `api/xero`
