import React from 'react'
import { Select, Input } from 'src/components'

const InvoiceLineItems = ({
  errorList,
  inputList,
  addLineItems,
  handleChangeOtherLineItems,
  orderInformationData,
  handleBlurDecimalLevel2,
  removeLineItems,
}) => {
  return (
    <>
      {/* Docket List */}
      <div className="d-flex mb-3">
        <h4 className="me-3">Other Invoice Line Items</h4>
        <button className="btn btn-success" onClick={(e) => addLineItems(e)}>
          Add Docket List
        </button>
      </div>

      <table id="example" className="table table-bordered table-striped">
        {inputList.other_line_items.length >= 1 && (
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
        )}

        <tbody>
          {inputList?.other_line_items?.map((item, i) => (
            <tr key={i}>
              <td>
                <Select
                  onChange={(e) => handleChangeOtherLineItems(e, i)}
                  name="line_item"
                  defaultValue={item.line_item}
                  options={orderInformationData.line_item}
                  formType={false}
                  address={false}
                />
                <span className="text-danger">{errorList['other_line_items.' + i + '.type']}</span>
              </td>
              <td>
                <Input
                  label={''}
                  placeholder={'Description'}
                  className={'form-control'}
                  type={'text'}
                  name={'description'}
                  value={item.description}
                  onChange={(e) => handleChangeOtherLineItems(e, i)}
                />
                <span className="text-danger">
                  {errorList['other_line_items.' + i + '.description']}
                </span>
              </td>
              <td>
                <Input
                  label={''}
                  placeholder={'Quantity'}
                  className={'form-control'}
                  type={'number'}
                  name={'quantity'}
                  value={item.quantity}
                  onChange={(e) => handleChangeOtherLineItems(e, i)}
                  onBlur={(e) => handleBlurDecimalLevel2(e, i, 'other_line_items')}
                />
                <span className="text-danger">
                  {errorList['other_line_items.' + i + '.quantity']}
                </span>
              </td>
              <td>
                <Input
                  label={''}
                  placeholder={'Amount'}
                  className={'form-control'}
                  type={'number'}
                  name={'amount'}
                  value={item.amount}
                  onChange={(e) => handleChangeOtherLineItems(e, i)}
                />
                <span className="text-danger">
                  {errorList['other_line_items.' + i + '.amount']}
                </span>
              </td>
              <td>
                <Input
                  label={''}
                  placeholder={'Total'}
                  className={'form-control'}
                  type={'number'}
                  name={'total'}
                  value={Number(Number(item.quantity) * Number(item.amount)).toFixed(3)}
                  disabled={true}
                />
              </td>
              <td>
                <div className="d-flex">
                  {inputList.other_line_items.length - 1 === i && (
                    <button
                      style={{ marginRight: '10px' }}
                      className="btn btn-success"
                      onClick={(e) => addLineItems(e)}
                    >
                      Add
                    </button>
                  )}
                  <button className="btn btn-danger" onClick={(e) => removeLineItems(e, i)}>
                    Remove
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default InvoiceLineItems
