import React, { useState, useEffect, useContext } from 'react'
import { useHistory, NavLink, Link } from 'react-router-dom'
import axios from 'axios'
import Preloader from 'src/views/preloader/Preloader'
import $ from 'jquery'
import DataTable from 'datatables.net'
import swal from 'sweetalert2'
import { Usercntxt } from '../../context/UserContext'
import { CFormCheck } from '@coreui/react'
import format from 'date-fns/format'
import { BlobProvider, PDFDownloadLink, usePDF } from '@react-pdf/renderer'
import MyDocument from './PDF/SingleOrder'
import TableRow from './TableRow'
function Open() {
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const [tableList, setTableList] = useState([])

  const [inputList, setInputList] = useState(2)
  const [showMore, setShowMore] = useState(false)
  const user = useContext(Usercntxt)

  useEffect(() => {
    axios.get(`/api/show_invoices`).then((res) => {
      if (res.data.status === 200) {
        //console.log(res.data.invoice)
        setTableList(res.data.invoice)
      } else if (res.data.status === 500) {
        swal.fire({
          icon: 'warning',
          title: 'warning',
          text: res.data.message,
        })
        history.push('/')
      }
      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
          order: [[0, 'desc']],
          autoWidth: false,
        })
      }, 10)
      setLoading(false)
    })
  }, [null])

  // const deleteProduct = (e, id) => {
  //   e.preventDefault()

  //   const thisClicked = e.currentTarget
  //   thisClicked.innerText = 'Deleting..'

  //   axios.delete(`/api/delete_product/${id}`).then((res) => {
  //     if (res.data.status === 200) {
  //       thisClicked.closest('tr').remove()
  //     } else if (res.data.status === 404) {
  //       thisClicked.innerText = 'Delete'
  //     }
  //   })
  // }

  //handle chages for checkboxes
  const handleRadio = (e) => {
    const { name, value } = e.target

    if (Number(value) === 2) {
      setLoading(true)
      axios.get(`/api/show_invoices`).then((res) => {
        if (res.data.status === 200) {
          setTableList(res.data.invoice)
        } else if (res.data.status === 500) {
          swal.fire({
            icon: 'warning',
            title: 'warning',
            text: res.data.message,
          })
          history.push('/')
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
            order: [[0, 'desc']],
            autoWidth: false,
          })
        }, 10)
        setLoading(false)
      })
    } else if (Number(value) === 1) {
      setLoading(true)
      axios.get(`/api/show_invoices`).then((res) => {
        if (res.data.status === 200) {
          setTableList(res.data.invoice_completed)
        } else if (res.data.status === 500) {
          swal.fire({
            icon: 'warning',
            title: 'warning',
            text: res.data.message,
          })
          history.push('/')
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
            order: [[0, 'desc']],
            autoWidth: false,
          })
        }, 10)
        setLoading(false)
      })
    } else if (Number(value) === 0) {
      setLoading(true)
      axios.get(`/api/show_invoices`).then((res) => {
        if (res.data.status === 200) {
          setTableList(res.data.invoice_progress)
        } else if (res.data.status === 500) {
          swal.fire({
            icon: 'warning',
            title: 'warning',
            text: res.data.message,
          })
          history.push('/')
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
            order: [[0, 'desc']],
          })
        }, 10)
        setLoading(false)
      })
    }
    setInputList(Number(value))
  }

  const deleteOrder = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the order permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/delete_order/${id}`).then((res) => {
            if (res.data.status === 200) {
              swal.fire('Deleted!', res.data.message, 'success')
              thisClicked.closest('tr').remove()
            } else if (res.data.status === 404) {
              swal.fire('Error', res.data.message, 'error')
            }
          })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  if (loading) {
    return <Preloader />
  } else {
  }

  return (
    <>
      <div className="content-wrapper">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Orders
            </li>
          </ol>
        </nav>
        <h3 className="text-dark">Orders</h3>
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6 p-0">
                {user.can('access orders') && (
                  <NavLink className="btn btn-primary mt-3 me-2" to="/orders/create">
                    Add New Order
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* body content dashboard */}
        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Orders</h5>

              <div className="form-group d-flex d-inline">
                <CFormCheck
                  onChange={(e) => handleRadio(e)}
                  type="radio"
                  name="status_filter"
                  id="flexRadioDefault2"
                  label="All"
                  value={2}
                  className="fs-5"
                  checked={inputList === 2}
                />
                <CFormCheck
                  onChange={(e) => handleRadio(e)}
                  type="radio"
                  name="status_filter"
                  id="flexRadioDefault1"
                  label="Complete"
                  value={1}
                  checked={inputList === 1}
                  className="fs-5 ms-3"
                />
                <CFormCheck
                  onChange={(e) => handleRadio(e)}
                  type="radio"
                  name="status_filter"
                  id="flexRadioDefault0"
                  label="Open"
                  value={0}
                  checked={inputList === 0}
                  className="fs-5 ms-3"
                />
              </div>
            </div>
            <div
              style={{ fontSize: '12px', padding: '0', paddingTop: '5px' }}
              className="card-body"
            >
              <table id="dataTable" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Account Contact</th>
                    <th>Address Contact</th>
                    <th>Order Notes</th>
                    <th>Docket Numbers</th>
                    <th>Product(s)</th>
                    <th>T O Volume</th>
                    <th>Delivery Location</th>
                    <th>Machine</th>
                    <th>Status</th>
                    <th>Last Modified</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {tableList.map((item, i) => (
                    <TableRow item={item} key={i} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* <pre>{JSON.stringify(tableList, null, 2)}</pre> */}
        </div>
      </div>
    </>
  )
}

export default Open
