import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import axios from 'axios'
import Preloader from '../preloader/Preloader'
import swal from 'sweetalert2'
import $ from 'jquery'
import 'datatables.net'
import { CSVLink } from 'react-csv' // For CSV download
import GoogleMapComponent from 'src/components/google_maps/GoogleMapComponent'

function Customers() {
  const [loading, setLoading] = useState(true)
  const [customerList, setCustomerList] = useState([])
  const [xeroCustomerList, setXeroCustomerList] = useState([])
  const [addressData, setAddressData] = useState([])

  useEffect(() => {
    axios.get(`/api/show_customers`).then((res) => {
      if (res.data.status === 200) {
        setCustomerList(res.data.customer)
        //console.log('Finding Contacts', res.data.customer)

        const filteredCustomers = res.data.xeroContact.body.Contacts.filter(
          (contact) => contact.IsCustomer === true || false,
        ).slice(0, 5000)

        console.log('System contacts', res.data.xeroContact.body.Contacts)

        setXeroCustomerList(res.data.xeroContact.body.Contacts)
        //console.log('Xero Customer List:', filteredCustomers)
      }

      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
        })
      }, 10)

      setLoading(false)
    })

    // Fetch address data
    axios.get(`/api/show_address`).then((res) => {
      if (res.data.status === 200) {
        // Transform data to include all other details as a string
        const transformedData = res.data.address.map((item) => ({
          id: item.id || '',
          customer_address_id: item?.customer_address_id || '',
          address: item.address || '',
          street: item.street || '',
          city: item.city || '',
          state: item.state || '',
          post_code: item.post_code || '',
          del_zone: item.del_zone || '',
          delivery_zone_id: item.delivery_zones?.id || '',
          delivery_zone_name: item.delivery_zones?.name || '',
          location_note: item.location_note || '',
          p_location: item.p_location || '',
          resident_contact: item.resident_contact || '',
          resident_contact_two: item.resident_contact_two || '',
          resident_name: item.resident_name || '',
          resident_name_two: item.resident_name_two || '',
          created_at: item.created_at || '',
          updated_at: item.updated_at || '',
        }))

        setAddressData(xeroCustomerList)

        // console.log('Transformed Address Data:', xeroCustomerList)
      } else {
        // console.error('Error fetching show_address:', res.data.message)
      }
    })
  }, [])

  useEffect(() => {
    if (!loading && xeroCustomerList.length > 0 && customerList.length > 0) {
      async function insertUpTo300() {
        const notFound = []
        let insertedCount = 0
        const updatedRecords = new Set() // Track updated records by ContactID

        for (const item of xeroCustomerList) {
          const matchingCustomer = customerList.find(
            (customer) => item.ContactID === customer.contact_id,
          )

          if (!matchingCustomer) {
            if (insertedCount < 25) {
              const payload = {
                ContactID: item.ContactID,
                Name: item.Name,
                FirstName: item.FirstName,
                Code: item.AccountNumber,
                Home: item.Phones[1]?.PhoneNumber,
                Mobile: item.Phones[3]?.PhoneNumber,
              }

              try {
                const response = await axios.post('/api/create_xero_contact', payload)
                console.log('Server response:', response.data)
              } catch (error) {
                console.error('Error sending matched data:', error)
              }

              insertedCount++
              await new Promise((resolve) => setTimeout(resolve, 200))
            } else {
              notFound.push(item)
            }
          } else {
            // Only update if this record hasn't already been updated in this run.
            if (updatedRecords.has(item.ContactID)) continue

            // Check if any field is different.
            if (
              matchingCustomer.account_name !== item.Name ||
              matchingCustomer.account_contact_name !== item.FirstName ||
              matchingCustomer.code !== item.AccountNumber ||
              matchingCustomer.phone_work !== item.Phones[1]?.PhoneNumber ||
              matchingCustomer.phone_mobile !== item.Phones[3]?.PhoneNumber
            ) {
              // Only update if we haven't reached the limit.
              if (insertedCount < 0) {
                const updatePayload = {
                  Name: item.Name,
                  FirstName: item.FirstName,
                  Code: item.AccountNumber,
                  Home: item.Phones[1]?.PhoneNumber,
                  Mobile: item.Phones[3]?.PhoneNumber,
                }

                try {
                  const response = await axios.put(
                    `/api/update_xero_contact/${item.ContactID}`,
                    updatePayload,
                  )
                  console.log('Update response:', response.data)
                  updatedRecords.add(item.ContactID)
                  insertedCount++ // Count this update toward the overall limit.
                  await new Promise((resolve) => setTimeout(resolve, 200))
                } catch (error) {
                  console.error('Error updating contact:', error)
                }
              } else {
                notFound.push(item)
              }
            }
          }
        }
        console.log('ContactIDs not processed due to limit:', notFound)
      }
      insertUpTo300()
    }
  }, [loading, xeroCustomerList, customerList])

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Customer Address ID', key: 'customer_address_id' },
    { label: 'Address', key: 'address' },
    { label: 'Street', key: 'street' },
    { label: 'City/Suburb', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Post code', key: 'post_code' },
    { label: 'Delivery Zone Name', key: 'delivery_zone_name' },
    { label: 'Location Note', key: 'location_note' },
    { label: 'Xero Contact ID', key: 'p_location' },
    { label: 'Resident Contact', key: 'resident_contact' },
    { label: 'Resident Contact Two', key: 'resident_contact_two' },
    { label: 'Resident Name', key: 'resident_name' },
    { label: 'Resident Name Two', key: 'resident_name_two' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
  ]

  const headerXero = [
    { label: 'Xero Contact ID', key: 'ContactID' },
    { label: 'Xero Account Code', key: 'AccountNumber' },
    { label: 'Contact Status', key: 'ContactStatus' },
    { label: 'Name', key: 'Name' },
    { label: 'First Name', key: 'FirstName' },
    { label: 'Last Name', key: 'LastName' },
    { label: 'Email Address', key: 'EmailAddress' },
    { label: 'Has Attachments', key: 'HasAttachments' },
    { label: 'Has Validation Errors', key: 'HasValidationErrors' },
    { label: 'Is Customer', key: 'IsCustomer' },
    { label: 'Is Supplier', key: 'IsSupplier' },
    { label: 'Payment Terms Sales Day', key: 'PaymentTerms.Sales.Day' },
    { label: 'Payment Terms Sales Type', key: 'PaymentTerms.Sales.Type' },
    { label: 'Phone DDI', key: 'Phones[0].PhoneNumber' },
    { label: 'Phone Default', key: 'Phones[1].PhoneNumber' },
    { label: 'Phone Fax', key: 'Phones[2].PhoneNumber' },
    { label: 'Phone Mobile', key: 'Phones[3].PhoneNumber' },
    { label: 'Address Street Type', key: 'Addresses[0].AddressType' },
    { label: 'Address Street City', key: 'Addresses[0].City' },
    { label: 'Address Street Region', key: 'Addresses[0].Region' },
    { label: 'Address POBOX Type', key: 'Addresses[1].AddressType' },
    { label: 'Address POBOX Line 1', key: 'Addresses[1].AddressLine1' },
    { label: 'Address POBOX City', key: 'Addresses[1].City' },
  ]

  if (loading) {
    return <Preloader />
  } else {
    const viewProduct_HTMLTable_Xero = [...xeroCustomerList]
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .map((item, i) => {
        const matchingCustomer = customerList.find(
          (customer) => customer.contact_id === item.ContactID,
        )
        /*  console.log(matchingCustomer?.customer_address)
      console.log(
        'Contact id',
        item.ContactID,
        'Customer contact id',
        matchingCustomer?.customer_address.contact_id,
      ) */

        return (
          <tr key={item.ContactID}>
            <td>
              {i + 1} {/* {item.ContactID} */}
            </td>
            <td>{item.Name}</td>
            <td>{item.FirstName}</td>
            <td>{item.AccountNumber}</td>
            <td>
              {item.Phones && item.Phones.length > 0
                ? item.Phones.map((phone) => phone.PhoneNumber) // Extract PhoneNumber
                    .filter((number) => number && number.trim() !== '') // Filter out null, undefined, or empty strings
                    .join(' | ') // Join with "|"
                : 'No phone numbers available'}
            </td>

            <td style={{ width: '300px' }}>
              {matchingCustomer?.customer_address
                .filter((address) => address.p_location === item.ContactID)
                .map((address, index) => (
                  <div key={index}>
                    {/* {console.log(address)} */}
                    <div>{address.address && <strong>{address.address}</strong>}</div>
                    {address.street && <div>{address.street}</div>}
                    {address.city && <div>{address.city}</div>}
                    {address.state && <div>{address.state}</div>}
                    {address.post_code && <div>{address.post_code}</div>}
                    {address?.delivery_zones?.name && <div>{address?.delivery_zones?.name}</div>}
                    {address.location_note && <div>{address.location_note}</div>}
                    {address.contacts && address.contacts.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <strong>Contacts:</strong>
                        {address.contacts.map((contactObj, cIndex) => (
                          <div key={cIndex}>
                            {contactObj.contact_name} - {contactObj.contact_number}
                          </div>
                        ))}
                      </div>
                    )}
                    {/* {(address.resident_name || address.resident_contact) && (
                    <div>
                      {address.resident_name} - {address.resident_contact}
                    </div>
                  )}
                  {(address.resident_name_two || address.resident_contact_two) && (
                    <div>
                      {address.resident_name_two} - {address.resident_contact_two}
                    </div>
                  )} */}
                  </div>
                ))}
            </td>
            <td>
              <Link
                to={`/customers/update/${item.ContactID}`}
                className="btn btn-block btn-outline-info btn-sm me-2 mb-2"
              >
                <i className="far fa-edit"></i> Edit
              </Link>
            </td>
          </tr>
        )
      })

    // Build CSV data from customerList
    const csvCustomerData = customerList.flatMap((customer) => {
      // Check if customer has any address
      if (customer.customer_address) {
        // Normalize customer_address to an array if it's not one
        const addresses = Array.isArray(customer.customer_address)
          ? customer.customer_address
          : [customer.customer_address]

        return addresses.flatMap((address) => {
          // If contacts exist and are an array with items
          if (Array.isArray(address.contacts) && address.contacts.length > 0) {
            return address.contacts.map((contact) => ({
              account_name: customer.account_name || '',
              contact_name: contact.contact_name || '',
              contact_number: contact.contact_number || '',
            }))
          }
          // If contacts exist as a single object (not an array) and not empty
          else if (
            address.contacts &&
            typeof address.contacts === 'object' &&
            Object.keys(address.contacts).length > 0
          ) {
            return [
              {
                account_name: customer.account_name || '',
                contact_name: address.contacts.contact_name || '',
                contact_number: address.contacts.contact_number || '',
              },
            ]
          }
          // If no contacts exist, output a row with empty contact details
          else {
            return [
              {
                account_name: customer.account_name || '',
                contact_name: '',
                contact_number: '',
              },
            ]
          }
        })
      }
      // If customer_address doesn't exist, still output a row with empty contacts
      return [
        {
          account_name: customer.account_name || '',
          contact_name: '',
          contact_number: '',
        },
      ]
    })

    const customerCsvHeaders = [
      { label: 'Account Name', key: 'account_name' },
      { label: 'Contact Name', key: 'contact_name' },
      { label: 'Contact Number', key: 'contact_number' },
    ]

    return (
      <div className="content-wrapper">
        <nav aria-label="breadcrumb p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Customers
            </li>
          </ol>
        </nav>

        <h3 className="text-dark">All Customers</h3>

        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">All Customers</h5>
              {/* CSV Download Button */}
              {/* <CSVLink
                data={xeroCustomerList}
                headers={headerXero}
                filename="show_address_data.csv"
                className="btn btn-success"
                style={{ float: 'right' }}
              >
                Download Address Data
              </CSVLink>

              <CSVLink
                data={csvCustomerData}
                headers={customerCsvHeaders}
                filename="customer_contacts.csv"
                className="btn btn-success"
                style={{ float: 'right', marginRight: '10px' }}
              >
                Download Customer Contacts CSV
              </CSVLink> */}
            </div>

            <div className="card-body">
              <table id="dataTable" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Account Name</th>
                    <th>Account Contact Name</th>
                    <th>Code</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{viewProduct_HTMLTable_Xero}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Customers
