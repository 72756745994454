import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { DateRangePicker } from 'rsuite'

import { usePDF, Document, Page, BlobProvider } from '@react-pdf/renderer'
import DailyReport from './PDF/Daily'
import { CSVLink, CSVDownload } from 'react-csv'
const CustomReport = () => {
  const [dateRange, setDateRange] = useState(null)
  const [error, setError] = useState('')
  const [loadingDate, setLoadingDate] = useState(false)
  const [csvdata, setCSVDate] = useState(null)

  const arrayRestrusture = (csvdata) => {
    //console.log(csvdata)
    let dt = []
    let obj = {}

    for (let i = 0; i < csvdata.length; i++) {
      const opr = csvdata[i].operator.reduce(
        (first, second, idx) =>
          idx == 0
            ? 'Name: ' +
              (second?.operator?.fname ?? 'N/A') +
              ' | M: ' +
              (second?.operator?.phone ?? 'N/A')
            : first +
              ' , ' +
              'Name: ' +
              (second?.operator?.fname ?? 'N/A') +
              ' | M: ' +
              (second?.operator?.phone ?? 'N/A'),
        '',
      )

      const prtdt = csvdata[i].order_list[0].order_list_products.reduce(
        (first, second, idx) =>
          idx == 0 ? second.product_name : first + ' | ' + second.product_name,
        ' ',
      )

      let newObj = {
        id: csvdata[i].id,
        created_at: csvdata[i].created_at ? formatDate(csvdata[i].created_at) : 'N/A',
        sub_type: csvdata[i].subordertype?.code ?? 'N/A',
        crop: csvdata[i].crop?.name ?? 'N/A',
        customer_account_contact_name: csvdata[i].customer?.account_contact_name ?? 'N/A',
        customer_account_name: csvdata[i].customer?.account_name ?? 'N/A',
        customer_phone_mobile: csvdata[i].customer?.phone_mobile ?? 'N/A',
        operators: opr ?? 'N/A',
        delevery_location: csvdata[i].customer_address?.address ?? 'N/A',
        delevery_resident_name: csvdata[i].customer_address?.resident_name ?? 'N/A',
        delevery_resident_contact: csvdata[i].customer_address?.resident_contact ?? 'N/A',
        products: prtdt ?? 'N/A',
        unit_remaining: csvdata[i].tons_ordered - csvdata[i].tons_actual ?? 'N/A',
        unit_complete: csvdata[i].tons_actual ?? 'N/A',
        unit_total: csvdata[i].tons_ordered ?? 'N/A',
        status: 'ready',
        scheduled_Date: csvdata[i].scheduled_date ? formatDate(csvdata[i].scheduled_date) : 'N/A',
        client_note: csvdata[i].customer_reference ?? 'N/A',
        driver_note: csvdata[i].driver_note ?? 'N/A',
        location_note: csvdata[i].customer_address?.location_note ?? 'N/A',
        order_note: csvdata[i].order_note ?? 'N/A',
        docket_date: csvdata[i].order_list[i]?.docket_numbers[i]?.docket_date ?? 'N/A',
      }
      obj = newObj
      dt.push(obj)
    }

    return dt
  }

  const getDataReport = (data) => {
    if (!data || !Array.isArray(data) || data.length !== 2) {
      setError(null)
      setCSVDate(null)
      setDateRange(null)
      return
    }
    setDateRange(data)
    setLoadingDate(true)
    if (data) {
      axios.post(`/api/get_custom_report`, { data }).then((res) => {
        if (res.data.orders.length >= 0) {
          setError('No data found')
        } else {
          setError(null)

          setCSVDate(res.data.csv)
        }
      })
    } else {
      setError(null)
      setDateRange(null)
    }
    setLoadingDate(false)
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Sub Order Type', key: 'sub_type' },
    { label: 'Crop Type', key: 'crop' },
    { label: 'Customer Account Name', key: 'customer_account_name' },
    { label: 'Customer Phone', key: 'customer_phone_mobile' },
    { label: 'Operators', key: 'operators' },
    { label: 'Delivery Location', key: 'delevery_location' },
    { label: 'Products', key: 'products' },
    { label: 'QtyRemaining', key: 'unit_remaining' },
    { label: 'QtyComplete', key: 'unit_complete' },
    { label: 'TotalQty', key: 'unit_total' },
    { label: 'Status', key: 'status' },
    { label: 'Scheduled Date', key: 'scheduled_Date' },
    { label: 'Client Note', key: 'client_note' },
    { label: 'Deliver Note', key: 'driver_note' },
    { label: 'Location Note', key: 'location_note' },
    { label: 'Order Note', key: 'order_note' },
    { label: 'Docket Date', key: 'docket_date' },
  ]

  return (
    <>
      <h1 className="mb-4">Custom Report</h1>
      <div className="d-flex align-items-end">
        <div className="d-flex flex-column ">
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            style={{ width: 230 }}
            onChange={(e) => getDataReport(e)}
            value={dateRange}
          />
        </div>
        <div className="mx-3">
          {error && <div className="mt-3 btn btn-block btn-outline-info btn-sm">{error}</div>}

          {loadingDate && (
            <div className="mt-3 btn btn-block btn-outline-info btn-sm">Loading....</div>
          )}
          {csvdata && !loadingDate && (
            <>
              <CSVLink
                className="btn btn-block btn-outline-info btn-sm mx-3"
                headers={headers}
                data={arrayRestrusture(csvdata)}
                // data={toCsv(pivot(csvdata))}
                filename={`${formatDate(dateRange[0])}-${formatDate(dateRange[1])}.csv`}
              >
                Download CSV
              </CSVLink>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CustomReport
