import React, { useContext, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert2'
import { Usercntxt } from '../../context/UserContext'
import format from 'date-fns/format'
import { BlobProvider } from '@react-pdf/renderer'
import MyDocument from './PDF/SingleOrder'

const TableRow = ({ item }) => {
  const [generateReport, setgenerateReport] = useState(false)
  const history = useHistory()
  const user = useContext(Usercntxt)
  const deleteOrder = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the order permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/delete_order/${id}`).then((res) => {
            if (res.data.status === 200) {
              swal.fire('Deleted!', res.data.message, 'success')
              thisClicked.closest('tr').remove()
            } else if (res.data.status === 404) {
              swal.fire('Error', res.data.message, 'error')
            }
          })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  return (
    <tr key={item.id}>
      <td>
        <p>#{item.id}</p>
      </td>
      <td>
        <p>{item?.customer?.code}</p>
      </td>
      <td>
        <p>
          <span className="fw-bold"> {item?.customer?.account_name}</span>
          {/* <br></br>
          <span className="fw-bold">H:</span>
          {item?.customer?.phone_home && item?.customer?.phone_home}
          <br></br>
          <span className="fw-bold">W:</span>
          {item?.customer?.phone_work && item?.customer?.phone_work}
          <br></br>
          <span className="fw-bold">M:</span>
          {item?.customer?.phone_mobile && item?.customer?.phone_mobile} */}
        </p>
      </td>
      <td>
        <p>
          <span className="fw-bold">{item?.customer?.account_contact_name}</span>
          <br></br>
          {item?.customer?.customer_address.map(
            (phone, i) => phone.resident_contact && phone.resident_contact + '\n',
          )}
        </p>
      </td>
      <td>
        <p>
          <span className="fw-bold"> {item?.order_note}</span>
        </p>
      </td>
      <td>
        <span>
          {item.order_list.map((list, i) =>
            list.docket_numbers.slice(0, 5).map((docket, i) => (
              <p key={i}>
                <span className="fw-bold">DN:</span> {docket.docket_number}
              </p>
            )),
          )}
        </span>
      </td>
      <td>
        {item.order_list?.map((product_code, i) => (
          <div key={i}>
            {product_code.order_list_products?.map((code, ind) => (
              <span className="badge bg-dark mx-1" key={ind}>
                {code.product_name}
                {ind === product_code.order_list_products.length - 1 ? '' : ''}
              </span>
            ))}
          </div>
        ))}
      </td>
      <td>
        <p>
          <span className="fw-bold">TO </span>
          {item.tons_ordered ? item.tons_ordered : 'N/A'} <span className="fw-bold">TA </span>
          {item.tons_actual ? item.tons_actual : 'N/A'}
        </p>
      </td>
      <td>
        <p>{item.orderdeliveryzone?.name}</p>
      </td>
      <td>
        <p>
          <span className="fw-bold">{item.machine_id === null ? 'N/A' : item.machine.name}</span>
          {item.operator?.map((data, i) => (
            <p key={i} className="p-0 m-0">
              {data.operator?.fname} {data.operator?.lname}
            </p>
          ))}
        </p>
      </td>
      <td>
        <span className="badge bg-primary mx-1">{item.order_status === '0' && 'In Progress'}</span>
        <span className="badge bg-success mx-1">{item.order_status === '1' && 'Completed'}</span>
      </td>

      <td>
        <p>{format(new Date(item.updated_at), 'dd/MM/yyyy | HH:mm:ss')}</p>
      </td>
      <td>
        <div className="d-flex">
          {user.can('access orders') && (
            <Link
              to={`/orders/update/${item.id}`}
              className="btn btn-block btn-outline-info btn-sm"
            >
              <i className="far fa-edit"></i>
            </Link>
          )}
          &nbsp;
          <br />
          {user.can('access orders') && (
            <button
              type="button"
              onClick={(e) => deleteOrder(e, item.id)}
              className="btn btn-block btn-outline-danger btn-sm"
            >
              <i className="far fa-trash-alt"></i>
            </button>
          )}
          &nbsp;
        </div>
        {/*  <br />
        {item.order_status === '1' && !generateReport && (
          <button className="btn btn-outline-info btn-sm" onClick={() => setgenerateReport(true)}>
            Generate Report
          </button>
        )}

        {generateReport && (
          <BlobProvider document={<MyDocument item={item} />}>
            {({ url, loading, error }) =>
              !loading ? (
                <a
                  className="btn btn-block btn-outline-primary btn-sm"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  Download
                </a>
              ) : (
                <a
                  className="btn btn-block btn-outline-info btn-sm"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  loading Report
                </a>
              )
            }
          </BlobProvider>
        )} */}
      </td>
    </tr>
  )
}
export default TableRow
