import React from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

// Setup pdfMake with default fonts (Helvetica)
pdfMake.vfs = pdfFonts.vfs

// Helper functions
const formatDate = (date) => {
  if (!date) return ''
  const d = new Date(date)
  if (isNaN(d)) return ''
  const day = d.getDate().toString().padStart(2, '0')
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const year = d.getFullYear()
  return `${day}/${month}/${year}`
}

const returnDate = () => {
  const currentdate = new Date()
  const day = currentdate.getDate().toString().padStart(2, '0')
  const month = (currentdate.getMonth() + 1).toString().padStart(2, '0')
  const year = currentdate.getFullYear()
  return `${day}/${month}/${year}`
}

const returnDatePDF = () => {
  const currentDate = new Date()
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const year = currentDate.getFullYear()
  // Use dashes for filenames (slashes aren't allowed)
  return `${day}-${month}-${year}`
}

/**
 * PDFGenerator replicates your DailyReport structure using pdfMake.
 * Assumed nested data structure: dt[zone][type][machine] = array of orders.
 * Mapping data (map_dt) provides dzone, otype, and machine names.
 */
const PDFGenerator = ({ dt, map_dt, xero_contact }) => {
  const generateDocDefinition = () => {
    const content = []

    // Top-right Date
    content.push({
      columns: [
        { text: '', width: '*' },
        {
          text: `Date: ${returnDate()}`,
          style: 'bold9right',
          width: 'auto',
        },
      ],
      margin: [0, 0, 0, 10],
    })

    // Loop over each zone (first layer)
    Object.keys(dt).forEach((zoneKey, zoneIndex) => {
      const zoneName = map_dt.dzone.find((x) => x.id === Number(zoneKey))?.name || ''
      const zoneContent = []

      // Zone header – each zone starts on a new page (except first)
      zoneContent.push({
        text: [
          { text: 'ZONE: ', style: 'zoneHeader' },
          { text: zoneName, style: 'reg8' },
        ],
        margin: [0, 0, 0, 5],
        pageBreak: zoneIndex > 0 ? 'before' : undefined,
      })

      // Loop over types inside this zone
      Object.keys(dt[zoneKey]).forEach((typeKey) => {
        const typeName = map_dt.otype.find((x) => x.id === Number(typeKey))?.name || ''
        let typePrinted = false // flag to print type only once

        // Loop over machines inside this type
        Object.keys(dt[zoneKey][typeKey]).forEach((machineKey) => {
          const machineName = map_dt.machine.find((x) => x.id === Number(machineKey))?.name || ''

          // For the first machine in this type group, print full header line
          if (!typePrinted) {
            zoneContent.push({
              text: [
                { text: 'TYPE: ', style: 'subHeader' },
                { text: typeName, style: 'reg8' },
                { text: '   MACHINE: ', style: 'subHeader' },
                { text: machineName, style: 'reg8' },
              ],
              margin: [0, 2, 0, 5],
            })
            typePrinted = true
          } else {
            // For subsequent machines, only print MACHINE header
            zoneContent.push({
              text: [
                { text: 'MACHINE: ', style: 'subHeader' },
                { text: machineName, style: 'reg8' },
              ],
              margin: [0, 2, 0, 5],
            })
          }

          // Data rows for each order in this machine group
          dt[zoneKey][typeKey][machineKey].forEach((dataItem) => {
            zoneContent.push({
              unbreakable: true,
              columns: [
                {
                  width: 120,
                  stack: [
                    { text: formatDate(dataItem.created_at), style: 'reg9' },
                    { text: dataItem.order_note, style: 'reg9' },
                  ],
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 120,
                  text: dataItem?.customer?.account_name || '',
                  style: 'reg9',
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  text: (() => {
                    const numbers = []

                    const customerContactId = dataItem?.customer?.contact_id
                    const xeroContacts = Array.isArray(xero_contact) ? xero_contact : []

                    // Add customer's mobile if it exists
                    if (customerContactId && xeroContacts.length > 0) {
                      // Filter the xero contacts that match the customer's contact_id
                      const matchedContacts = xeroContacts.filter(
                        (xeroContact) => xeroContact.ContactID === customerContactId,
                      )

                      // Iterate over each matched Xero contact and extract phone numbers
                      matchedContacts.forEach((contact) => {
                        if (Array.isArray(contact?.Phones)) {
                          // If Phones is an array, get all PhoneNumber values
                          const phoneNumbers = contact.Phones.map(
                            (phone) => phone.PhoneNumber,
                          ).filter(Boolean)
                          numbers.push(...phoneNumbers)
                        } else if (contact?.Phones?.PhoneNumber) {
                          // In case Phones is a single object
                          numbers.push(contact.Phones.PhoneNumber)
                        }
                      })
                    }

                    // Add all contacts' contact numbers from customer_address, if they exist
                    if (Array.isArray(dataItem?.customer_address?.contacts)) {
                      const contactsNumbers = dataItem.customer_address.contacts
                        .map((contact) => contact.contact_number)
                        .filter(Boolean)
                      numbers.push(...contactsNumbers)
                    }

                    console.log('Extracted phone numbers:', numbers)

                    // Join using newline character so that each number appears on a new line
                    return numbers.join('\n') || ''
                  })(),
                  style: 'reg9',
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  // Show city from first customer address if available; otherwise fallback
                  text:
                    dataItem?.invoice?.customer_address?.city ||
                    dataItem?.customer_address?.city ||
                    '',
                  style: 'reg9',
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  stack: (() => {
                    const products = dataItem?.order_list?.[0]?.order_list_products
                    if (!products || products.length === 0) return []
                    if (products.length >= 2) {
                      const lines = []
                      lines.push({
                        text: `MIX - ${Number(dataItem.tons_ordered).toFixed(3)}`,
                        style: 'reg9',
                      })
                      products.forEach((prod) => {
                        lines.push({
                          text: `${prod.product_name || ''} ${Number(prod.quantity).toFixed(3)}`,
                          style: 'reg10',
                        })
                      })
                      return lines
                    }
                    return products.map((prod) => ({
                      text: `${prod.product_name || ''}, ${prod.quantity}${
                        prod.quantitytype?.quantity_value &&
                        prod.quantitytype.quantity_value !== '1'
                          ? ` x ${prod.quantitytype.quantity_value}`
                          : ''
                      }`,
                      style: 'reg9',
                    }))
                  })(),
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  color: 'red',
                  stack: [
                    { text: dataItem?.custom_spread_rate || '', style: 'reg9' },
                    { text: dataItem?.driver_note || '', style: 'reg9' },
                  ],
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  text:
                    dataItem.dockets_ready == 1
                      ? 'READY'
                      : dataItem.dockets_ready == 2
                      ? 'WILL ADVICE'
                      : dataItem.dockets_ready == 3
                      ? 'IN PROGRESS'
                      : '',
                  style: 'reg9',
                  margin: [0, 2, 0, 2],
                },
                {
                  width: 100,
                  text: formatDate(dataItem.scheduled_date),
                  style: 'reg9',
                  margin: [0, 2, 0, 2],
                },
              ],
              margin: [0, 0, 0, 2],
            })
            // Always add a horizontal line after each order row
            zoneContent.push({
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 880, // total width of columns: 120+120+120+100+200+100+100 = 880
                  y2: 0,
                  lineWidth: 0.5,
                  lineColor: 'gray',
                },
              ],
              margin: [0, 5, 0, 5],
            })
          })
        })
      })

      content.push({
        stack: zoneContent,
      })
    })

    return {
      pageOrientation: 'landscape',
      content,
      styles: {
        zoneHeader: {
          fontSize: 12,
          bold: true,
          marginBottom: 5,
        },
        subHeader: {
          fontSize: 12,
          bold: true,
        },
        bold9right: {
          fontSize: 8,
          bold: true,
          alignment: 'right',
        },
        reg9: {
          fontSize: 8,
          lineHeight: 1.5,
        },
        reg8: {
          fontSize: 12,
          lineHeight: 1.5,
          color: 'blue',
        },
        reg10: {
          fontSize: 8,
          lineHeight: 1.5,
          color: 'red',
        },
      },
    }
  }

  const downloadPDF = () => {
    const docDefinition = generateDocDefinition()
    pdfMake.createPdf(docDefinition).download(`DailyReport_${returnDatePDF()}.pdf`)
  }

  return (
    <div>
      <button onClick={downloadPDF} className="btn btn-block btn-outline-info btn-sm">
        Download Report
      </button>
    </div>
  )
}

export default PDFGenerator
