import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

const styles = StyleSheet.create({
  heading: {
    color: 'black',
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 5,
  },
  subHeading: {
    color: 'blue',
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 5,
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.2,
    alignItems: 'flex-start',
  },
  th: {
    flex: 1,
    margin: 0,
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  tablebody: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.2,
    alignItems: 'flex-start',
  },
  tr: {
    flex: 1,
    margin: 0,
    padding: 5,
    fontSize: 10,
  },
  total: {
    marginHorizontal: 5,
    borderTop: '1px solid black',
    fontWeight: 'bold',
  },
  grandTotalParent: {
    marginTop: 10,
  },
  grandTotal: {
    margin: 5,
    borderTop: '2px solid black',
    fontWeight: 'bold',
  },
})

const Spread = (data) => {
  const dt = data?.item ?? null
  const map_dt = data?.map_data ?? null
  const dateRange = data?.dateRange ?? null

  if (!dt || !map_dt) {
    return (
      <Document>
        <Page size="A4" orientation="portrait">
          <Text>Error: Data not available</Text>
        </Page>
      </Document>
    )
  }

  const formatDate = (date) => {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  // Format the date range (assumed to be an array [start, end]).
  const formatDateRange = (range) => {
    if (!range || !Array.isArray(range)) return ''
    const [start, end] = range
    return `${formatDate(start)} to ${formatDate(end)}`
  }

  // Helper function to compute tonnage for a single row.
  const computeRowTonnes = (dtRow, product) => {
    // Find the corresponding order_list_product to get mix_percentage
    const found = map_dt.order_list_products.find(
      (x) =>
        x.order_id == Number(dtRow.id) &&
        x.product_mix_id === map_dt.product.find((x) => x.id === Number(product))?.id,
    )
    const mixPercentage = found ? found.mix_percentage : 0
    return (Number(mixPercentage) / 100) * Number(dtRow.tons_ordered)
  }

  const computeRowTonnesActual = (dtRow, product) => {
    // Find the corresponding order_list_product to get mix_percentage
    const found = map_dt.order_list_products.find(
      (x) =>
        x.order_id == Number(dtRow.id) &&
        x.product_mix_id === map_dt.product.find((x) => x.id === Number(product))?.id,
    )
    const mixPercentage = found ? found.mix_percentage : 0
    return (Number(mixPercentage) / 100) * Number(dtRow.tons_actual)
  }

  const computeRowCompleted = (dtRow, product) => {
    // Find the corresponding order_list_product to get mix_percentage
    const found = map_dt.order_list_products.find(
      (x) =>
        x.order_id == Number(dtRow.id) &&
        x.product_mix_id === map_dt.product.find((x) => x.id === Number(product))?.id,
    )
    const mixPercentage = found ? found.mix_percentage : 0

    if (dtRow.order_status == 1) {
      return (Number(mixPercentage) / 100) * Number(dtRow.tons_actual)
    } else {
      return (Number(mixPercentage) / 100) * Number(dtRow.tons_ordered)
    }
  }

  // Compute the grand total across all zones and products.
  const computeGrandTotal = () => {
    let total = 0
    Object.keys(dt).forEach((zone) => {
      Object.keys(dt[zone]).forEach((product) => {
        dt[zone][product].forEach((dtRow) => {
          total += computeRowTonnes(dtRow, product)
        })
      })
    })
    return total.toFixed(3)
  }

  // Compute the grand total across all zones and products.
  const computeGrandTotalOne = () => {
    let total = 0
    Object.keys(dt).forEach((zone) => {
      Object.keys(dt[zone]).forEach((product) => {
        dt[zone][product].forEach((dtRow) => {
          total += computeRowTonnesActual(dtRow, product)
        })
      })
    })
    return total.toFixed(3)
  }

  // Compute the grand total across all zones and products.
  const computeGrandTotalTwo = () => {
    let total = 0
    Object.keys(dt).forEach((zone) => {
      Object.keys(dt[zone]).forEach((product) => {
        dt[zone][product].forEach((dtRow) => {
          total += computeRowCompleted(dtRow, product)
        })
      })
    })
    return total.toFixed(3)
  }

  const zones = (data, map_dt) => {
    return (
      <>
        <Text style={styles.heading}>
          Tonnes Spread {dateRange ? `- ${formatDateRange(dateRange)}` : ''}
        </Text>
        <View style={styles.table}>
          <Text style={styles.th}>Customer Name</Text>
          <Text style={styles.th}>Spread Date</Text>
          <Text style={styles.th}>Order Date</Text>
          <Text style={styles.th}>Tonnes Ordered</Text>
          <Text style={styles.th}>Tonnes Actual</Text>
          <Text style={styles.th}>Predicted</Text>
        </View>
        {Object.keys(data).map((zone) => (
          <View key={zone}>
            <Text style={styles.heading}>
              {map_dt.dzone.find((x) => x.id === Number(zone))?.name}
            </Text>
            {Object.keys(data[zone]).map((product) => (
              <View key={product}>
                <Text style={styles.subHeading}>
                  {map_dt.product.find((x) => x.id === Number(product))?.product_docket}
                </Text>
                {data[zone][product].map((dtRow, j) => (
                  <View key={j} style={styles.tablebody}>
                    <Text style={styles.tr}>{dtRow.customer.account_name}</Text>
                    <Text style={styles.tr}>{dtRow.tons_complete_time}</Text>
                    <Text style={styles.tr}>{formatDate(dtRow.created_at)}</Text>
                    <Text style={styles.tr}>{computeRowTonnes(dtRow, product).toFixed(3)}</Text>
                    <Text style={styles.tr}>
                      {computeRowTonnesActual(dtRow, product).toFixed(3)}
                    </Text>
                    <Text style={styles.tr}>{computeRowCompleted(dtRow, product).toFixed(3)}</Text>
                  </View>
                ))}

                {/* ComputedRowTons */}
                {data[zone][product].length > 1 && (
                  <View style={styles.tablebody}>
                    <Text style={styles.tr}></Text>
                    <Text style={styles.tr}></Text>
                    <Text style={styles.tr}></Text>
                    <Text style={[styles.tr, styles.total]}>
                      {data[zone][product]
                        .map((dtRow) => computeRowTonnes(dtRow, product))
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        .toFixed(3)}
                    </Text>

                    <Text style={[styles.tr, styles.total]}>
                      {data[zone][product]
                        .map((dtRow) => computeRowTonnesActual(dtRow, product))
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        .toFixed(3)}
                    </Text>

                    <Text style={[styles.tr, styles.total]}>
                      {data[zone][product]
                        .map((dtRow) => computeRowCompleted(dtRow, product))
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        .toFixed(3)}
                    </Text>
                  </View>
                )}
              </View>
            ))}
          </View>
        ))}
      </>
    )
  }

  const grandTotal = computeGrandTotal()
  const grandTotalOne = computeGrandTotalOne()
  const grandTotalTwo = computeGrandTotalTwo()

  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap>
        {zones(dt, map_dt)}
        <View style={[styles.tablebody, styles.grandTotalParent]}>
          <Text style={styles.tr}>GRAND TOTAL</Text>
          <Text style={styles.tr}></Text>
          <Text style={styles.tr}></Text>
          <Text style={[styles.tr, styles.grandTotal]}>{grandTotal}</Text>
          <Text style={[styles.tr, styles.grandTotal]}>{grandTotalOne}</Text>
          <Text style={[styles.tr, styles.grandTotal]}>{grandTotalTwo}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default Spread
